<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div :class="['tabs', 'c-tabs']" :id="id">
		<ul :class="['tabs__header', hasBottom == false ? 'no-bottom' : '']">
			<template v-for="tab in tabs" :key="tab.id">
				<li 
					:class="[ 'c-tab', tab.id == selectedTabId ? 'selected' : '', tab.isHidden ? 'hidden' : '']"
					@click="onSelectTab(tab)"
					v-if="tab && !tab.isHidden"
				>
					{{ tab.title }}
				</li>
			</template>
		</ul>
		<div v-show="hasContent">
			<slot></slot>
		</div>
	</div>
</template>






<script>
// Credit: https://www.youtube.com/watch?v=ROGeJxI2L58
// Credit: https://github.com/mattmaribojoc/learn-vue-tab

import { ref } from 'vue';
export default {
	setup(props, { slots }) 
	{
		console.log("slots.default()=", slots.default());
		let tabsFromSlots = slots.default().filter(tab => tab.props);	// Only tabs that have props. Why? if the parent is using `v-if` to hide some tabs.
		tabsFromSlots = tabsFromSlots.map((tab) => {
			return { title: tab.props.title, id: tab.props.id, isHidden: tab.props.isHidden };
		});

		const tabs = ref(tabsFromSlots);

		const selectedTabId = ref(tabs.value[0].id);

		return {
			tabs,
			selectedTabId,
		}
	},





	props: {
		/**
		 * The `id` of this tab set. Required.  
		 * Should be unique among all elements currently rendered on the page.
		 */
		id: {
			type: String,
			required: true
		},



		/**
		 * ID of the tab to select and display initially.
		 */
		initialTabId: {
			type: String,
			required: false,
			default: ''
		},



		/**
		 * Set to false to show only the tabs themselvs and hide the content area (content can be handled externally).
		 */
		hasContent: {
			type: Boolean,
			required: false,
			default: true
		},



		/**
		 * Does the tab strip have a bottom border and margin?
		 */
		hasBottom: {
			type: Boolean,
			required: false,
			default: true
		},
	},


	
	data() {
		return {
		}
	},



	created() {
		//this.tabs = this.$children
	},



	mounted() {
		this.init();
	},


	emits: ["select"],


	methods: {
		init()
		{

			// If an initial tab is defined, select it.
			let initTabId = '';
			if (this.initialTabId)
			{
				//const _this = this;
				const initTab = this.tabs.find(z => z.id == this.initialTabId);
				if (initTab)
				{
					initTabId = initTab.id;
				}

				if (initTabId)
				{
					this.selectedTabId = initTabId;
				}
			}
		},



		onSelectTab(tab)
		{
			this.selectedTabId = tab.id;

			this.$emit('select', { tab: tab });
		}
	}
}
</script>






<style lang="scss" scoped>

.tabs
{
	width: 100%;
	margin: 0 auto;
}


.hidden
{
	display: none;
}




.tabs__header
{
	border-bottom: 1px solid #dddddd;
	margin-bottom: 10px;
	
	list-style: none;
	padding: 0;
	display: flex;
}

.tabs__header.no-bottom
{
	margin-bottom: 0;
	border-bottom: none;
}


/*// Verison 1*/
.tabs__header li
{
	min-width: 180px;
	text-align: center;
	padding: 10px 20px;
	
	border-left: 1px solid #dddddd;
	border-top: 1px solid #dddddd;

	background-color: #ffffff;
	/* border-radius: 5px;*/
	cursor: pointer;
	transition: 0.3s all ease-out;

	font-weight: 600;
}

.tabs__header li:first-child
{
	border-top-left-radius: 6px;
}
.tabs__header li:last-child
{
	border-top-right-radius: 6px;
	border-right: 1px solid #dddddd;
}


.tabs__header li:hover
{
	color: rgb(64, 158, 255);
	// text-decoration: underline;
}




.tabs__header li.selected
{
	background-color: rgb(64, 158, 255);
	color: #ffffff;

	border-top-color: rgb(64, 158, 255);
	border-bottom-color: rgb(64, 158, 255);
	border-left-color: rgb(64, 158, 255);
}

.tabs__header li:last-child.selected
{
	border-right-color: rgb(64, 158, 255);
}
</style>