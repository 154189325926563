<template>
	<span class="simulate-form-control" style="display: inline-block;">
		<input 
			type="date" 
			:id="id" 
			:name="getName" 
			:class="['c-input-date', 'c-input-native-base-restyle', cssClass, isValid == false ? 'is-invalid' : '']" 
			:style="getCssStyle"
			:value="modelValue"
			@input="onInput"
		>
		<span class="validation-text" v-if="isValid == false && validationMessage != ''">
			<small style="color: red" v-html="validationMessage"></small>
		</span>
	</span>
</template>





<script>
import allFormElementPropsMixin from "./all-form-element-props-mixin";

export default {

	mixins: [allFormElementPropsMixin],





	emits: ["change", "update:modelValue"],






	props: {

		//TODO: Obsolete/deprecated. Don't remove until removing from implementations.
		initialValue: {
			type: String,
			default: ""
		},



		//TODO: Obsolete/deprecated. Don't remove until removing from implementations.
		/**
		 * Set this from the parent page/component when wanting to 
		 * programatically update the selected value.
		 */
		updateValue: {
			type: String,
		},



		type: {
			type: String,
			default: "native"
		},



        modelValue: {
            type: [String, null],
            default: '',
            required: true
        },

	},





	data()
	{
		return {
			value: ""
		};
	},





	created()
	{
	},





	watch: {
	},





	computed: {
		getName() {
			return this.name ? this.name : this.id;
		}
	},





	methods: {
		onInput(event)
		{
			this.$emit('change', event.target.value);

			this.$emit('update:modelValue', event.target.value);
		},
	}
}
</script>





<style scoped lang="scss">
	@import "/assets/sass/_global.scss";



</style>