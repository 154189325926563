/**
 * Global Lotus AI configuration.
 * This object can injected into the page via ASP.NET.
 * It can be extended from withing .js though.
 */
const config = {
	logoutRootRelativePath: "/logout",
	loginRootRelativePath: "/login",

	forgotPasswordRootRelativePath: "/forgot-password",
	resetPasswordRootRelativePath: "/reset-password",

	defaultPathAfterSuccessfulLogin: "/",


    api: {
        baseUrl: {
            development: "https://localhost:44393",

			//-- So I don't have to run up API in development
			//development: "https://lotusai-test-api.azurewebsites.net",
			//development: "https://api.lotusai.co",



			//test: "https://lotusaitest.azurewebsites.net",	//TODO: Old/original
			test: "https://lotusai-test-api.azurewebsites.net",

			staging: "",	//TODO: Not implemented

			//production: "https://lotusai-production-portal.azurewebsites.net",	//TODO: Old/original
			//production: "https://lotusai-prod-portal-api.azurewebsites.net",
			production: "https://api.lotusai.co",

			//alpha: "https://lotusai-production-api-alpha.azurewebsites.net"	//TODO: Old/original
			//alpha: "https://lotusai-alpha-api.azurewebsites.net"
			alpha: "https://alpha.api.lotusai.co"
		}
	},




	filesApi: {
        baseUrl: {
            development: "https://localhost:7062",

			//-- So I don't have to run up API in development
			//development: "https://files.api.lotusai.co",

			
			test: "https://lotusai-test-files-api.azurewebsites.net",

			staging: "",	//TODO: Not implemented
			production: "https://files.api.lotusai.co",
			alpha: "https://files.api.lotusai.co"
		}
	},





    /**
     * Return the base URL to the primary Lotus back-end API for the current envrionment.
     */
    GetLotusApiBaseUrl()
    {
        const environment = this.GetEnvironmentCode();

		const url = this.api.baseUrl[environment];
		
		if (environment == "unknown" || url == undefined)
		{
			console.error("Config::GetLotusApiBaseUrl(" + environment + "): Something went wrong. Unknown environment. url=", url);
		}
		else
		{
			//console.log("Config::GetLotusApiBaseUrl(" + environment + "): url=", url);
			//console.log("Config::GetLotusApiBaseUrl()->getCurrentDomain()=", this.getCurrentDomain());
		}

        return url;
    },


    /**
     * Return the base URL to the Lotus "Files" back-end API for the current envrionment.
     */
    GetLotusFilesApiBaseUrl()
    {
        const environment = this.GetEnvironmentCode();

		const url = this.filesApi.baseUrl[environment];
		
		if (environment == "unknown" || url == undefined)
		{
			console.error("Config::GetLotusFilesApiBaseUrl(" + environment + "): Something went wrong. Unknown environment. url=", url);
		}
		else
		{
			//console.log("Config::GetLotusFilesApiBaseUrl(" + environment + "): url=", url);
			//console.log("Config::GetLotusFilesApiBaseUrl()->getCurrentDomain()=", this.getCurrentDomain());
		}

        return url;
    },



    

    /**
     * Check if the current environment is "Production".
     * 
     * @returns bool
     */
    IsProduction()
    {
		if (
			this.getCurrentDomain() == "portal.lotusai.co" ||
			this.getCurrentDomain() == "lotusai.co"
		)
        {
            return true;
        }

        return false;
	},
	




    IsProductionAlpha()
    {
		if (
			this.getCurrentDomain() == "alpha.portal.lotusai.co" ||
			this.getCurrentDomain() == "lotus-portal-production-alpha.netlify.app"
		)
        {
            return true;
        }

        return false;
    },




	//TODO: Note - there is no staging yet.
    /**
     * Check if the current environment is "Staging".
     * 
     * @returns bool
     */
    IsStaging()
    {
        if (this.getCurrentDomain() == "staging-portal.lotusai.co")
        {
            return true;
        }

        return false;
    },





    /**
     * Check if the current environment is "Test".
     * 
     * @returns bool
     */
    IsTest()
    {
		if (
			this.getCurrentDomain() == "test.portal.lotusai.co" || 
			this.getCurrentDomain() == "lotus-portal-test.netlify.app" ||
			this.getCurrentDomain() == "lotus-portal-test2.netlify.app" ||
			//this.getCurrentDomain() == "lotusai-v2-portal-test.netlify.app" ||
			//this.getCurrentDomain() == "lotusai-public-test.netlify.app" ||
			//this.getCurrentDomain() == "lotus-portal-develop.netlify.app" || 
			this.getCurrentDomain() == "lotusai-test-tessta.netlify.app"
		)
        {
            return true;
        }

        return false;
    },




    /**
     * Check if the current environment is "Development".
     * 
     * @returns bool
     */
    IsDevelopment()
    {
        if (this.getCurrentDomain() == "localhost" || this.getCurrentDomain() == "127.0.0.1")
        {
            return true;
        }

        return false;
    },





    /**
     * Return the code for the current environment.
     * 
     * @returns string Code of the current environment, otherwise "unknown".
     */
    GetEnvironmentCode()
    {
		if (this.IsProduction()) return "production";
		if (this.IsProductionAlpha()) return "alpha";
		if (this.IsTest()) return "test";
		if (this.IsStaging()) return "staging";
        if (this.IsDevelopment()) return "development";

        return "unknown";
	},
	

    GetEnvironmentName()
    {
		if (this.IsProduction()) return "Production";
		if (this.IsProductionAlpha()) return "Production (Alpha)";
		if (this.IsTest()) return "Test";
		if (this.IsStaging()) return "Staging";
        if (this.IsDevelopment()) return "Development";

        return "unknown";
    },




	
    /**
     * Return the "domain" component of the URL this site is running on.
     * 
     * @returns string
     */
    getCurrentDomain()
    {
        return window.location.hostname;
    }
};




export default config;