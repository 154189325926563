<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<form v-on:submit.prevent="submitHandler" class="form-horizontal" method="post" role="form">
		<slot></slot>
	</form>
</template>




<script>
//TODO: Not implemented. Currently a placeholder for an idea. Not sure when I'll go ahead with it.
export default {
	props: {
		submitHandler: {
			type: Function,
			required: false,
		},
	}
}
</script>




<style>

</style>