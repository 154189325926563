<template>
	<span class="simulate-form-control" style="display: inline-block;">
		<input 
			type="time" 
			:id="id" :name="getName" 
			:class="['c-input-time', 'c-input-time-native', 'c-input-native-base-restyle', cssClass, isValid == false ? 'is-invalid' : '']" 
			:style="getCssStyle"
			:disabled="disabled"
			:value="modelValue"
			@input="onInput"
		/>
		
		<span class="validation-text" v-if="isValid == false && validationMessage != ''">
			<small style="color: red" v-html="validationMessage"></small>
		</span>
	</span>
</template>


<script>
import allFormElementPropsMixin from "./all-form-element-props-mixin";

export default {

	emits: ["change","update:modelValue"],

	mixins: [allFormElementPropsMixin],

	props: {

		//TODO: Obsolete/deprecated. Don't remove until removing from implementations.
		initialValue: {
			type: String
		},


		start: {
			type: String,
			default: "00:00"
		},
		

		end: {
			type: String,
			default: "00:15"
		},
		

		step: {
			type: String,
			default: "24:00"
		},


        modelValue: {
            type: String,
            default: '',
            required: true
        },
	},





	data()
	{
		return {
			value: "",

			pickerOptions: {
				start: "",
				step: "",
				end: ""
			}
		};
	},

	



	mounted()
	{
	},




	
	watch: {

	},





	computed: {
		getName() {
			return this.name ? this.name : this.id;
		}
	},





	methods: {
		//TODO: Obsolete. Remove.
		onClick()
		{
			this.$emit('click', {});
		},



		onInput(event)
		{
			//console.log("onInput(): ", event.target.value);

			this.$emit('change', event.target.value);

			this.$emit('update:modelValue', event.target.value);
		},
	}
}
</script>





<style scoped lang="scss">
	@import "/assets/sass/_global.scss";

	.c-input-time 
	{
		
	}


	// Grabbed this styling from the <el-time-select> element so keep it consistent.
	.c-input-time-native
	{
		-webkit-appearance: none;
		background-color: #FFF;
		background-image: none;
		border-radius: 4px;
		border: 1px solid #DCDFE6;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		color: #606266;
		display: inline-block;
		font-size: inherit;
			font-size: 14px;
		font-family: inherit;
		height: 40px;
		line-height: 40px;
		outline: 0;
		padding: 0 15px;
		-webkit-transition: border-color .2s cubic-bezier(.645,.045,.355,1);
		transition: border-color .2s cubic-bezier(.645,.045,.355,1);
		/*width: 100%;*/
	}


	// Disabled styling same as Element UI on native time picker
	.c-input-time-native:disabled
	{
		background-color: #F5F7FA;
		border-color: #E4E7ED;
		color: #C0C4CC;
		cursor: not-allowed;
	}

</style>