const routes = [

	//---- Module: "Tessta" (admin portal)
	{ 
		path: '/module/tessta/example-endpoint', 
		name: "tessta-admin-example", 
		component: () => import("./module.tessta/example-endpoint.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta Example" }
	},




	{ 
		path: '/module/tessta', 
		name: "tessta-admin-root", 
		component: () => import("./module.tessta/home/index.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta" }
	},

	{ 
		path: '/module/tessta/home', 
		name: "tessta-admin-home-index", 
		component: () => import("./module.tessta/home/index.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Home" }
	},


	{ 
		path: '/module/tessta/clients', 
		name: "tessta-admin-clients-index", 
		component: () => import("./module.tessta/clients/index.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Clients" }
	},
	{ 
		path: '/module/tessta/clients/create', 
		name: "tessta-admin-clients-create", 
		//component: () => import("./module.tessta/clients/create-client.vue"),
		component: () => import("./module.tessta/clients/edit-client.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Clients > Create" }
	},
	{ 
		path: '/module/tessta/clients/edit/:id', 
		name: "tessta-admin-clients-edit", 
		component: () => import("./module.tessta/clients/edit-client.vue"),
		props:true,
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Clients > Edit" }
	},


	{ 
		path: '/module/tessta/sites', 
		name: "tessta-admin-sites-index", 
		component: () => import("./module.tessta/sites/index.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Sites" }
	},
	// { 
	// 	path: '/module/tessta/sites/create', 
	// 	name: "tessta-admin-sites-create", 
	// 	component: () => import("./module.tessta/sites/create-site.vue"),
	// 	meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Sites > Create" }
	// },
	{ 
		path: '/module/tessta/sites/create', 
		name: "tessta-admin-sites-create", 
		props:true, 
		component: () => import("./module.tessta/sites/edit-site.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Sites > Create" }
	},

	{ 
		path: '/module/tessta/sites/edit/:siteId', 
		name: "tessta-admin-sites-edit",
		props:true, 
		component: () => import("./module.tessta/sites/edit-site.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Sites > Edit" }
	},

	//TODO: Obsolete
	// { 
	// 	path: '/module/tessta/assessment/:assessmentId', 
	// 	name: "tessta-admin-sites-assessment",
	// 	props:true, 
	// 	component: () => import("./module.tessta/sites/assessment-site.vue"),
	// 	meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Sites > Assessment" }
	// },


	{ 
		path: '/module/tessta/projects', 
		name: "tessta-admin-projects-index", 
		component: () => import("./module.tessta/projects/index.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Projects" }
	},
	{ 
		path: '/module/tessta/projects/create', 
		name: "tessta-admin-projects-create", 
		component: () => import("./module.tessta/projects/edit-project.vue"),	//TODO: Change this to a correct create page
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Projects > Create" }
	},
	{ 
		path: '/module/tessta/projects/edit/:projectId', 
		name: "tessta-admin-projects-edit", 
		component: () => import("./module.tessta/projects/edit-project.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Projects > Project" }
	},


	{ 
		path: '/module/tessta/projects/my-app-data', 
		name: "tessta-admin-user-app-data", 
		component: () => import("./module.tessta/projects/user-app-data/user-app-data.vue"),
		meta: { layout: "no", module: "tessta", pageTitle: "Tessta > My current live app data" }
	},


	//


	{ 
		path: '/module/tessta/qa', 
		name: "tessta-admin-qa-index", 
		component: () => import("./module.tessta/qa/index.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > QA" }
	},


	{ 
		path: '/module/tessta/defects', 
		name: "tessta-admin-defects-index", 
		component: () => import("./module.tessta-shared/defects/index.vue"),
		meta: { layout: "primary2", module: "tessta", pageTitle: "Tessta > Defects" },
		props:true, 
	},
	{ 
		path: '/module/tessta/defects/view/:defectId', 
		name: "tessta-admin-defects-edit",
		props:true,
		component: () => import("./module.tessta-shared/defects/view-defect.vue"),
		meta: { layout: "primary2", module: "tessta", pageTitle: "Tessta > Defects > View Defect" }
	},


	{ 
		path: '/module/tessta/configuration', 
		name: "tessta-admin-configuration-index", 
		component: () => import("./module.tessta/configuration/index.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Configuration" }
	},

	{ 
		path: '/module/tessta/configuration/users', 
		name: "tessta-admin-configuration-users-index", 
		component: () => import("./module.tessta/configuration/users.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Configuration > Users" }
	},
	{ 
		path: '/module/tessta/configuration/users/edit/:userId', 
		name: "tessta-admin-configuration-user-edit", 
		component: () => import("./module.tessta/configuration/edit-user.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Configuration > Users > User" }
	},

	{ 
		path: '/module/tessta/configuration/assessments', 
		name: "tessta-admin-configuration-assessments-index", 
		component: () => import("./module.tessta/configuration/assessments.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Configuration > Assessments" }
	},
	{ 
		path: '/module/tessta/configuration/assessments/create', 
		name: "tessta-admin-configuration-assessment-create", 
		component: () => import("./module.tessta/configuration/edit-assessment.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Configuration > Assessments > Create Assessment" }
	},
	{ 
		path: '/module/tessta/configuration/assessments/edit/:assessmentId', 
		name: "tessta-admin-configuration-assessment-edit", 
		component: () => import("./module.tessta/configuration/edit-assessment.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Configuration > Assessments > Edit Assessment" }
	},

	{ 
		path: '/module/tessta/configuration/assets', 
		name: "tessta-admin-configuration-assets-index", 
		component: () => import("./module.tessta/configuration/assets.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Configuration > Assets" }
	},
	{ 
		path: '/module/tessta/configuration/assets/create', 
		name: "tessta-admin-configuration-asset-create", 
		component: () => import("./module.tessta/configuration/edit-asset.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Configuration > Assets > Create Asset" }
	},
	{ 
		path: '/module/tessta/configuration/assets/edit/:assetId', 
		name: "tessta-admin-configuration-asset-edit", 
		component: () => import("./module.tessta/configuration/edit-asset.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Configuration > Assets > Edit Asset" }
	},

	{ 
		path: '/module/tessta/configuration/reference-data', 
		name: "tessta-admin-configuration-reference-data-index", 
		component: () => import("./module.tessta/reference-data/index.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Configuration > Reference Data" }
	},

	//TODO: Obsolete for now. All editing is done on the index page for now.
	// { 
	// 	path: '/module/tessta/configuration/reference-data/edit/:id', 
	// 	name: "tessta-admin-configuration-reference-data-edit", 
	// 	component: () => import("./module.tessta/reference-data/edit-referene-data.vue"),
	// 	meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Configuration > Reference Data > Edit" }
	// },


	//TODO: The following now all come under "Assets" I think.

	{ 
		path: '/module/tessta/configuration/work-orders', 
		name: "tessta-admin-configuration-work-orders-index", 
		component: () => import("./module.tessta/configuration/work-orders.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Configuration > Work Orders" }
	},
	{ 
		path: '/module/tessta/configuration/work-orders/edit/:workOrderId', 
		name: "tessta-admin-configuration-work-orders-edit", 
		component: () => import("./module.tessta/configuration/edit-work-order.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Configuration > Work Orders > Work Order" }
	},

	{ 
		path: '/module/tessta/configuration/room-elements', 
		name: "tessta-admin-configuration-room-elements-index", 
		component: () => import("./module.tessta/configuration/room-elements.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Configuration > Room Elements" }
	},
	{ 
		path: '/module/tessta/configuration/room-elements/edit/:roomElementId', 
		name: "tessta-admin-configuration-room-elements-edit", 
		component: () => import("./module.tessta/configuration/edit-room-element.vue"),
		meta: { layout: "primary", module: "tessta", pageTitle: "Tessta > Configuration > Room Elements > Room Element" }
	},

]


export default routes;