<template>
	<!-- No goruping -->
	<div v-if="isSearchable">
		<input 
			type="text"
			:placeholder="searchablePlaceholderText"
			:id="id" 
			:name="getName" 
			:list="id + '-options'" 
			:class="['c-input-select', multiple ? 'c-input-select-multiple' : '', 'c-input-native-base-restyle', cssClass, isValid == false ? 'is-invalid' : '']" 
			v-model="value" 
			:disabled="disabled"
			@change="onChange"
		/>
		<datalist :id="id + '-options'" :name="getName + '-options'">
			<option v-for="option in getOptions" :value="option.value" :key="id + '-' + option.value">{{option.text}}</option>
		</datalist>
	</div>

	<!-- No goruping -->
	<select 
		:id="id" 
		:name="getName" 
		:class="['c-input-select', multiple ? 'c-input-select-multiple' : '', 'c-input-native-base-restyle', 'c-input-native-not-grouped', cssClass, isValid == false ? 'is-invalid' : '']" 
		:style="getCssStyle"
		filterable
		v-model="value" 
		:disabled="disabled"
		@change="onChange"
		:multiple="multiple ? true : false"
		v-if="!isGrouped && !isSearchable">
		<option v-for="option in getOptions" :value="option.value" :key="id + '-' + option.value">{{option.text}}</option>
	</select>

	<!-- Grouped -->
	<select 
		:id="id"
		:name="getName" 
		:class="['c-input-select', multiple ? 'c-input-select-multiple' : '', 'c-input-native-base-restyle', 'c-input-native-grouped', cssClass, isValid == false ? 'is-invalid' : '']" 
		:style="getCssStyle"
		filterable
		v-model="value" 
		:disabled="disabled"
		@change="onChange"
		:multiple="multiple ? true : false"
		v-if="isGrouped && !isSearchable">
		<template v-for="item in groupedOptions" :key="'one' + item.value">
			<option :value="item.value" v-if="!item.children">{{ item.text }}</option>

			<template v-if="item.children">
				<optgroup :label="item.text">
					<option v-for="option in item.children" :key="item.value + option.value" :value="option.value">{{ option.text }}</option>
				</optgroup>
			</template>
		</template>
	</select>

	<span class="validation-text" v-if="isValid == false && validationMessage != ''">
		<small style="color: red" v-html="validationMessage"></small>
	</span>
</template>





<script>
import allFormElementPropsMixin from "./all-form-element-props-mixin";

export default {

	emits: ["change", "update:modelValue"],
	
	mixins: [allFormElementPropsMixin],

	props: {

		//TODO: Obsolete/deprecated. Don't remove until removing from implementations.
		initialValue: {
			type: [ Number, String, Array, null ]
		},
		


		/**
		 * A standard setup of options with "value" and "text" properties.
		 * Use this for a standard list WIHTOUT option groups.
		 */
		options: {
			type: [ Array, null ],
			default: null
		},



		/**
		 * Does the list have option groups with options inside.
		 */
		isGrouped: {
			type: Boolean,
			required: false,
			default: false
		},



		/**
		 * Can the list be searched?
		 * If `true`, this will override `isGrouped` (though we should look at integrating the 2 in the future).
		 */
		isSearchable: {
			type: Boolean,
			required: false,
			default: false
		},

		searchablePlaceholderText: {
			type: String,
			required: false,
			default: "Start typing, press down, or click to show options to select"
		},



		/**
		 * A standard setup of options with "value" and "text" properties.
		 * Use this for a list WITH option groups.
		 * 
		 * Item structure:
		 * {
		 *   value: "",
		 *   text: "",
		 *   children: [
		 *     {
		 *        value: "",
		 *        text: "",
		 *     }
		 *   ]
		 * }
		 * If `children` does NOT exist on an object, it's just a top level <option>.
		 * If `children` DOES exist then it is an <optgroup> and the children are the <options>s
		 */
		groupedOptions: {
			type: Array,
			default: () => []
		},



		//TODO: Not implemented
		// placeholder: {
		// 	type: String,
		// 	required: false,
		// 	default: "Select"
		// },


	
		/**
		 * ** DEPRECATED **  
		 * A custom data set, where "value" and "text" for standard "options"
		 * are mapped from the "dataValue" and "dataText" (name of the properties in the data items) respectively.
		 */
		data: {
			type: Array,
			default: null
		},

		/**
		 * ** DEPRECATED **  
		 */
		dataValue: {
			type: String,
			default: ""
		},

		/**
		 * ** DEPRECATED **  
		 */
		dataText: {
			type: String,
			default: ""
		},



		/**
		 * Set to `true` if multi-select is enabled.
		 */
		multiple: {
			type: Boolean,
			default: false
		},



        modelValue: {
            type: [ Number, String, Array, null ],
            default: '',
            required: true
        },

	},





	data()
	{
		return {
			value: []
		};
	},





	mounted()
	{
		this.init();
	},





	created()
	{
	},





	watch: {
		modelValue: function(to, from)
		{
			//console.log("watch[modelValue][" + this.id + "]: value=", this.value, "to=", to, "from=", from);
		
			this.syncModelValue(to);
		}
	},





	computed: {
		/**
		 * Get the value of the "name" property of the rendered SELECT element.
		 */
		getName() 
		{
			return this.name ? this.name : this.id;
		},



		/**
		 * Return the data structure containing the valuyes to render into 
		 * OPTION elements, based on the type of source data passed into the props.
		 */
		getOptions()
		{
			if (this.data)
			{
				// Generate an options array from the supplied raw data and identified value and text fields.
				var options = this.$root.getSelectOptionsFromData(this.data, this.dataValue, this.dataText);
				return options;
			}
			else
			{
				// Use a correctly pre-formatted options list.
				return this.options;
			}
		}
	},





	methods: {
		init()
		{
			this.syncModelValue(this.modelValue);
		},



		syncModelValue(toValue)
		{
			// console.log("syncModelValue(" + this.id + "): toValue=", toValue);
		
			this.value = toValue;
			if (!toValue) this.value = "";
		
			if (this.multiple && !toValue)
			{
				//console.log("   -- watch[modelValue][" + this.id + "]: MULTIPLE",);
				this.value = [];
			}
		},



		onChange()
		{
			this.$emit('change', this.value);

			this.$emit('update:modelValue', this.value);
		},
	}
}
</script>





<style scoped lang="scss">
	@import "/assets/sass/_global.scss";


.c-input-select
{
	width: 100%;

	// text-indent: 5px;


	option
	{
		padding: 4px;
	}


	option:checked
	{
		color: rgb(64, 158, 255);
		font-weight: bold;
	}
}

.c-input-select-multiple
{
	// This should cover the first 5 items in the list
	height: 142px;
}
</style>