<template>
	<input 
		type="email" 
		:id="id" 
		:name="getName" 
		:class="['c-input-email', 'c-input-native-base-restyle', cssClass, isValid == false ? 'is-invalid' : '']" 
		:style="getCssStyle"
		:value="modelValue"
		@input="onInput"
		:disabled="disabled"
		data-lpignore="true"
	>
	<span class="validation-text" v-if="isValid == false && validationMessage != ''">
		<small style="color: red" v-html="validationMessage"></small>
	</span>
</template>





<script>
//Note: `data-lpignore="true"` to stop LastPass icon
import allFormElementPropsMixin from "./all-form-element-props-mixin";


export default {


	emits: ["change", "update:modelValue"],





	mixins: [allFormElementPropsMixin],





	props: {

		//TODO: Obsolete/deprecated. Don't remove until removing from implementations
		initialValue: {
			type: String,
			default: ""
		},



        modelValue: {
            type: [String, null],
            default: '',
            required: true
        },

	},





	data()
	{
		return {
			value: ""
		};
	},





	mounted()
	{
	},





	computed: {
		getName() {
			return this.name ? this.name : this.id;
		}
	},





	methods: {

		onInput(event)
		{
			this.$emit('change', event.target.value);

			this.$emit('update:modelValue', event.target.value);
		},

	}
}
</script>





<style scoped lang="scss">
	@import "/assets/sass/_global.scss";
	
	.c-input-email 
	{
		width: 100%;
	}
</style>