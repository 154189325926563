<template>
	<button
		:id="id" 
		:name="getName"
		:value="initialValue" 
		:class="['c-native-button', 'c-button-standard', getTypeClass, getClass, getSizeClass]" 
		:style="getCssStyle"
		:type="nativeType"
		:disabled="disabled ? true : false"
		@click="onClickNative"
		v-if="origin=='native'"
	>
		<!-- <span class="icon" v-if="$slots.icon"><slot name="icon"></slot></span>
		<span class="text"><slot name="text"></slot></span> -->
		<span class="icon" v-if="hasIcon()"><slot name="icon"></slot></span>
		<span class="text" v-if="hasText()"><slot name="text"></slot></span>
	</button>
</template>





<script>
//TODO: The current `:type="displayType"` is actually wrong. If anything, it should be using `:type="nativeType".

//TODO: Dev refactoring note (15 Dec 2024):
// I've removed the following el-button specific props from the native button
/*
	:native-type="nativeType"
	:size="getSize"
*/
// I've also remove `:type="displayType"` and replaced it with a default `type="button"` until we implement a proper replacement - e.g. using the 'nativeType' prop.
//TODO: 23 Dec 2024: Returning `nativeType`/`native-type` so we can set the native `type=""` on buttons.


import buttonPropsMixin from "./button-props-mixin";
import allFormElementPropsMixin from "../forms/all-form-element-props-mixin";

export default {

	mixins: [allFormElementPropsMixin, buttonPropsMixin],


	props: {
		// Where is the underlying button coming from. Just needed while we transition from element to native and have to test.
		origin: {
			type: String,
			default: "native"	// "element" (<el-button>) or "native" (<button>)
		},
	},

	mounted()
	{
		this.displayType = this.type ? this.type : "default";

		//-- From 'button-cancel'
		// this.displayType = "info"; 
	},




	computed: {
		getTypeClass() {
			// console.log("button-standard::getTypeClass(): this.type=", this.type);
			if (this.type) return "button-type-" + this.type;

			return "button-type-standard";
			// if (this.size.toLowerCase() == "standard".toLowerCase()) return "default";
			
			// if (this.size.toLowerCase() == "small".toLowerCase()) return "small";
			
			// //NOTE: Had to add this when upgrading to Vue 3 and switching from element-ui to element-plus.
			// //if (this.size.toLowerCase() == "mini".toLowerCase()) return "mini";
			// if (this.size.toLowerCase() == "mini".toLowerCase()) return "small";

			// if (this.size.toLowerCase() == "large".toLowerCase()) return "large";

			// return "";
		},



		getNativeType()
		{
			return "button"

		}
	},





	methods: {
		hasIcon()
		{
			//Ref: https://stackoverflow.com/questions/50133153/get-rendered-html-contents-of-vue-slot-as-variable
			//Ref: https://eslint.vuejs.org/rules/require-slots-as-functions.html
			// Part 1) Ensure the slot is actually included in the calling component.
			// Part 2) Check there is HTML content within the slot in the calling component.
			const hasValue = !!this.$slots['icon'] && this.$slots.icon().length > 0;
			return hasValue;
		},

		hasText()
		{
			//Ref: https://stackoverflow.com/questions/50133153/get-rendered-html-contents-of-vue-slot-as-variable
			//Ref: https://eslint.vuejs.org/rules/require-slots-as-functions.html
			// Part 1) Ensure the slot is actually included in the calling component.
			// Part 2) Check there is HTML content within the slot in the calling component.
			const hasValue = !!this.$slots['text'] && this.$slots.text().length > 0;
			return hasValue;
		},







	}

}
</script>






<style scoped lang="scss">
@import "./shared-styles.scss";

	.icon {
		display: inline-block;
		margin-right: 10px;	
	}





	

	.c-native-button
	{
		border-radius: 4px;

		font-family: CircularStd, Montserrat, sans-serif;
		font-size: 14px;
		font-weight: 500;
		
		padding: 4px;
		padding-left: 16px;
		padding-right: 16px;
	}

	.c-native-button:hover
	{
		outline: 0;
	}

	.c-native-button:active {
		outline: 0;
	}













	// Copied from button-standard
	.button-type-standard,
	.button-type-primary
	{
		background-color: rgb(64, 158, 255);
		border: 1px solid rgb(64, 158, 255);
		border-radius: 4px;

		color: white;
	}


	// Copied from <el-button> type="primary"
	.button-type-standard:hover,
	.button-type-primary:hover
	{
		color: #ffffff;
		border-color: #79bbff;
		background-color: #79bbff;
	}

	// Copied from <el-button> type="primary"
	.button-type-standard:active,
	.button-type-primary:active {
		color: #ffffff;
		border-color: #337ecc;
		background-color: #337ecc;
	}


	.button-type-standard:disabled,
	.button-type-primary:disabled
	{
		background-color: rgb(159.5, 206.5, 255);
		border: 1px solid rgb(159.5, 206.5, 255);

		color: rgb(255, 255, 255);
		cursor: not-allowed;
	}











	//-- From 'button-cancel'
	.button-type-tertiary
	{
		background-color: rgb(244, 244, 245);
		border: 1px solid rgb(200, 201, 204);

		color: rgb(144, 147, 153);
	}


	.button-type-tertiary:hover
	{
		color: #ffffff;
		border-color: #909399;
		background-color: #909399;
	}

	.button-type-tertiary:active {
		color: #ffffff;
		border-color: #73767a;
		background-color: #73767a;
		
	}

	.button-type-tertiary:disabled
	{
		background-color: rgb(215, 215, 215);
		border: 1px solid rgb(215, 215, 215);

		color: rgb(255, 255, 255);
		cursor: not-allowed;
	}










	.button-type-secondary
	{
		background-color: #909399;
		border: 1px solid #909399;

		color: #ffffff;
	}


	.button-type-secondary:hover
	{
		// color: #ffffff;
		border-color: rgb(177.3, 179.4, 183.6);
		background-color:rgb(177.3, 179.4, 183.6);
	}

	.button-type-secondary:active {
		// color: #ffffff;
		border-color: rgb(199.5, 201, 204);
		background-color: rgb(199.5, 201, 204);
		
	}

	.button-type-secondary:disabled
	{
		background-color: rgb(215, 215, 215);
		border: 1px solid rgb(215, 215, 215);

		// color: rgb(255, 255, 255);
		cursor: not-allowed;
	}















	.button-type-danger
	{
		background-color: #f56c6c;
		border: 1px solid #f56c6c;

		color: #ffffff;
	}


	.button-type-danger:hover
	{
		// color: #ffffff;
		border-color: rgb(248, 152.1, 152.1);
		background-color: rgb(248, 152.1, 152.1);
	}

	.button-type-danger:active {
		// color: #ffffff;
		border-color: rgb(196, 86.4, 86.4);
		background-color: rgb(196, 86.4, 86.4);
		
	}

	.button-type-danger:disabled
	{
		background-color: rgb(250, 181.5, 181.5);
		border: 1px solid rgb(250, 181.5, 181.5);

		color: rgb(255, 255, 255);
		cursor: not-allowed;
	}














	.button-type-warning
	{
		background-color: #e6a23c;
		border: 1px solid #e6a23c;

		color: #ffffff;
	}


	.button-type-warning:hover
	{
		color: #ffffff;
		border-color: rgb(237.5, 189.9, 118.5);
		background-color: rgb(237.5, 189.9, 118.5);
	}

	.button-type-warning:active {
		color: #ffffff;
		border-color: rgb(184, 129.6, 48);
		background-color: rgb(184, 129.6, 48);
		
	}

	.button-type-warning:disabled
	{
		background-color: rgb(242.5, 208.5, 157.5);
		border: 1px solid rgb(242.5, 208.5, 157.5);

		color: rgb(255, 255, 255);
		cursor: not-allowed;
	}















	.button-type-success
	{
		background-color: #67c23a;
		border: 1px solid #67c23a;

		color: #ffffff;
	}


	.button-type-success:hover
	{
		color: #ffffff;
		border-color: rgb(148.6, 212.3, 117.1);
		background-color: rgb(148.6, 212.3, 117.1);
	}

	.button-type-success:active {
		color: #ffffff;
		border-color: rgb(82.4, 155.2, 46.4);
		background-color: rgb(82.4, 155.2, 46.4);
		
	}

	.button-type-success:disabled
	{
		background-color: rgb(179, 224.5, 156.5);
		border: 1px solid rgb(179, 224.5, 156.5);

		color: rgb(255, 255, 255);
		cursor: not-allowed;
	}
















	.button-type-link
	{
		background-color: transparent;
		border: 1px solid transparent;

		color: #909399;
	}


	.button-type-link:hover
	{
		background-color: transparent;
		border: 1px solid transparent;
	}

	.button-type-link:active {
		color: rgb(115.2, 117.6, 122.4);
		background-color: transparent;
		border: 1px solid transparent;
		
	}

	.button-type-link:disabled
	{
		color: rgb(199.5, 201, 204);
		cursor: not-allowed;
	}




















	//TODO: No. We have specific action type buttons. Or, move them in here?

	.button-type-action
	{
		background-color: rgb(244, 244, 245);
		border: 1px solid rgb(200, 201, 204);

		color: rgb(144, 147, 153);
	}


	.button-type-action:hover
	{
		color: #ffffff;
		border-color: #909399;
		background-color: #909399;
	}

	.button-type-action:active {
		color: #ffffff;
		border-color: #73767a;
		background-color: #73767a;
		
	}

	.button-type-action:disabled
	{
		background-color: rgb(215, 215, 215);
		border: 1px solid rgb(215, 215, 215);

		color: rgb(255, 255, 255);
		cursor: not-allowed;
	}






















	.size-standard
	{
		//TODO: Implement. See el-button for examples
	}

	.size-large
	{
		//TODO: Implement. See el-button for examples
		height: 62px;
		font-size: 20px;
		padding-left: 11px;
		padding-right: 11px;
	}

	.size-small
	{
		height: 28px;
		font-size: 12px;
		padding-left: 11px;
		padding-right: 11px;
	}

	.size-mini
	{
		//TODO: Implement. See el-button for examples
	}
</style>