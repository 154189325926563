<template>
	<el-date-picker
		:id="id" 
		:name="getName" 
		:class="['c-input-date-range', cssClass, isValid == false ? 'is-invalid' : '']" 
		:style="getCssStyle"
		
		v-model="value"
		
		type="daterange"
		range-separator="To"
		start-placeholder="Start date"
		end-placeholder="End date"
		format="DD MMM YYYY"
		value-format="YYYY-MM-DD"
		@change="onChangeEl"
		v-if="type == 'element'">
	</el-date-picker>

	<span class="validation-text" v-if="isValid == false && validationMessage != ''">
		<small style="color: red" v-html="validationMessage"></small>
	</span>
</template>





<script>
import allFormElementPropsMixin from "./all-form-element-props-mixin";

export default {

	emits: ["change", "update:modelValue"],

	mixins: [allFormElementPropsMixin],

	props: {

		//TODO: Obsolete/deprecated. Don't remove until removing from implementations.
		initialValue: {
			type: Array,
			default: () => []
		},



		//TODO: Obsolete/deprecated. Don't remove until removing from implementations.
		/**
		 * Set this from the parent page/component when wanting to 
		 * programatically update the selected value.
		 */
		updateValue: {
			type: Array,
		},



		type: {
			type: String,
			default: "element"
		},



        modelValue: {
            type: Array,
            default: () => [],
            required: true
        },

	},





	data()
	{
		return {
			value: []
		};
	},





	created()
	{
		this.value = this.modelValue;
	},





	mounted()
	{
		this.value = this.modelValue;
	},





	watch: {

	},





	computed: {
		getName() {
			return this.name ? this.name : this.id;
		}
	},





	methods: {
		onChangeEl()
		{
			// console.log("c-input-date-range:onChangeEl(): this.value=", this.value);

			this.$emit('change', this.value);

			this.$emit('update:modelValue', this.value);
		},
	}
}
</script>





<style scoped lang="scss">
	@import "/assets/sass/_global.scss";
</style>