const routes = [

	

	//---- "Developers" (which includes "Developer Guide", but that's a separate section)
	{ 
		path: '/developers/home', 	//NOTE: 18 July - not currently in the primary nav just because it's not needed. Add it when needed.
		name: "developers-home", 
		component: () => import("./developers/index.vue"),
		meta: { layout: "primary", pageTitle: "Developers > Home" }
	},



	//NOTE: 18 July - not currently in the primary nav just because it's not needed. Add it when needed.
	{ 
		path: '/developers/prototypes/mobile-app', 	
		name: "developers-prototypes-mobile-app", 
		component: () => import("./developers/prototypes/mobile-app.vue"),
		meta: { layout: "no", pageTitle: "Developers > Prototypes > Mobile App" }
	},
	{ 
		path: '/developers/prototypes/desktop-app',
		name: "developers-prototypes-desktop-app", 
		component: () => import("./developers/prototypes/mobile-app.vue"),
		meta: { layout: "no", pageTitle: "Developers > Prototypes > Desktop App" }
	},
	{ 
		path: '/developers/prototypes/desktop-app-notifications',
		name: "developers-prototypes-desktop-app-notifications", 
		component: () => import("./developers/prototypes/mobile-app.vue"),
		meta: { layout: "no", pageTitle: "Developers > Prototypes > Desktop App > Notifications" }
	},





	






	//---- "Developer Guide" (previously "Style Guide")
	{ 
		path: '/developers/dev-guide', 
		name: "dev-guide-home", 
		component: () => import("./developers/dev-guide/index.vue"),
		meta: { layout: "primary", pageTitle: "Developer Guide" }
	},

		//-- Portal/Components
		{ 
			path: '/developers/dev-guide/portal/components/buttons/:component',
			name: "dev-guide-portal-components-buttons", 
			component: () => import("./developers/dev-guide/portal/components/buttons/index.vue"),
			meta: { layout: "primary", pageTitle: "Developer Guide > Portal > Components > Buttons" }
		},
		{ 
			path: '/developers/dev-guide/portal/components/forms/:component',
			name: "dev-guide-portal-components-forms", 
			component: () => import("./developers/dev-guide/portal/components/forms/index.vue"),
			meta: { layout: "primary", pageTitle: "Developer Guide > Portal > Components > Forms" }
		},
		{ 
			path: '/developers/dev-guide/portal/components/files/:component',
			name: "dev-guide-portal-components-files", 
			component: () => import("./developers/dev-guide/portal/components/files/index.vue"),
			meta: { layout: "primary", pageTitle: "Developer Guide > Portal > Components > Files" }
		},
		{ 
			path: '/developers/dev-guide/portal/components/graphics/:component',
			name: "dev-guide-portal-components-graphics", 
			component: () => import("./developers/dev-guide/portal/components/graphics/index.vue"),
			meta: { layout: "primary", pageTitle: "Developer Guide > Portal > Components > Graphics" }
		},
		{ 
			path: '/developers/dev-guide/portal/components/layout/:component',
			name: "dev-guide-portal-components-layout", 
			component: () => import("./developers/dev-guide/portal/components/layout/index.vue"),
			meta: { layout: "primary", pageTitle: "Developer Guide > Portal > Components > Layout" }
		},
		{ 
			path: '/developers/dev-guide/portal/components/other/:component',
			name: "dev-guide-portal-components-other", 
			component: () => import("./developers/dev-guide/portal/components/other/index.vue"),
			meta: { layout: "primary", pageTitle: "Developer Guide > Portal > Components > Other" }
		},
		{ 
			path: '/developers/dev-guide/portal/components/tables/:component',
			name: "dev-guide-portal-components-tables", 
			component: () => import("./developers/dev-guide/portal/components/tables/index.vue"),
			meta: { layout: "primary", pageTitle: "Developer Guide > Portal > Components > Tables" }
		},
		{ 
			path: '/developers/dev-guide/portal/components/tabs/:component',
			name: "dev-guide-portal-components-tabs", 
			component: () => import("./developers/dev-guide/portal/components/tabs/index.vue"),
			meta: { layout: "primary", pageTitle: "Developer Guide > Portal > Components > Tabs" }
		},
		{ 
			path: '/developers/dev-guide/portal/components/typography/:component',
			name: "dev-guide-portal-components-typography", 
			component: () => import("./developers/dev-guide/portal/components/typography/index.vue"),
			meta: { layout: "primary", pageTitle: "Developer Guide > Portal > Components > Typography" }
		},
		{ 
			path: '/developers/dev-guide/portal/components/timesheets/:component',
			name: "dev-guide-portal-components-timesheets", 
			component: () => import("./developers/dev-guide/portal/components/timesheets/index.vue"),
			meta: { layout: "primary", pageTitle: "Developer Guide > Portal > Components > Timesheets" }
		},


		{ 
			path: '/developers/dev-guide/portal/helpers/:helper',
			name: "dev-guide-portal-helpers", 
			component: () => import("./developers/dev-guide/portal/helpers/index.vue"),
			meta: { layout: "primary", pageTitle: "Developer Guide > Portal > Helpers" }
		},


	{ 
		path: '/developers/dev-guide/element-ui', 
		name: "dev-guide-element-ui", 
		component: () => import("./developers/dev-guide/element-ui.vue"),
		meta: { layout: "primary", pageTitle: "Developer Guide > Element UI" }
	},


	{ 
		path: '/developers/dev-guide/test-calendar', 
		name: "dev-guide-test-calendar", 
		component: () => import("./developers/dev-guide/test-calendar.vue"),
		meta: { layout: "primary", pageTitle: "Developer Guide > Test Calendar" }
	},
	{ 
		path: '/developers/dev-guide/test-calendar/:timesheetId', 
		name: "dev-guide-test-calendar-with-timesheet", 
		component: () => import("./developers/dev-guide/test-calendar.vue"),
		meta: { layout: "primary", pageTitle: "Developer Guide > Test Calendar" }
	},
	{ 
		path: '/developers/dev-guide/test-keyword-extraction', 
		name: "dev-guide-test-keyword-extraction", 
		component: () => import("./developers/dev-guide/test-keyword-extraction.vue"),
		meta: { layout: "primary", pageTitle: "Developer Guide > Test Keyword Extraction" }
	},








	



	
	//---- Debugger
	{ 
		path: '/debugger/mobile/location-with-comments', 
		name: "DebuggerMobileLocationWithComments",
		component: () => import("./developers/debugger/mobile-location-with-comments.vue"),
		meta: { layout: "primary", pageTitle: "Debugger: Mobile > Location With Comments", kbFile: "", kbTitle: "" } 
	},

	{ 
		path: '/debugger/debug-logs', 
		name: "debugger-debug-logs",
		component: () => import("./developers/debugger/debug-logs.vue"),
		meta: { layout: "primary", pageTitle: "Debugger: Debug Logs", kbFile: "", kbTitle: "" } 
	},



]


export default routes;