const routes = [

	
	//---- "Mobile App" web view pages 
	//     (for direct access from the mobile app, either in a web view or in a native browser)
	//NOTE: These are from the original version of the Lotus app, for the quoting module.
	{ 
		path: '/mobile-app/manage-jobs',
		name: "mobile-app-manage-jobs", 
		component: () => import("./mobile-app/manage-jobs.vue"),
		meta: { layout: "mobileappembeddedview", layoutType: "default", layoutContainer: "card", pageTitle: "Manage Jobs" }
	},
	{ 
		path: '/mobile-app/new-job',
		name: "mobile-app-new-job", 
		component: () => import("./mobile-app/edit-job.vue"),
		meta: { layout: "mobileappembeddedview", layoutType: "default", layoutContainer: "card", pageTitle: "New Job" }
	},
	{ 
		path: '/mobile-app/edit-job/:jobId',
		name: "mobile-app-edit-job", 
		component: () => import("./mobile-app/edit-job.vue"),
		meta: { layout: "mobileappembeddedview", layoutType: "default", layoutContainer: "card", pageTitle: "Modify Job" }
	},




















	//---- "Mobile App" V2 - a web native "app" for running in the browser.
	{ 
		path: '/app/home',
		name: "mobile-app-home", 
		component: () => import("./mobile-app/home.vue"),
		meta: { layout: "mobileapp", layoutType: "default", layoutContainer: "card", pageTitle: "Home" }
	},




















	//---- "Mobile App" V2: for "Simpatico"
	//TODO: Under development
	{ 
		path: '/app/module/simpatico/shifts',
		name: "mobile-app-simpatico-shifts", 
		component: () => import("./mobile-app/module/simpatico/shifts.vue"),
		meta: { layout: "no", layoutType: "default", layoutContainer: "card", pageTitle: "Simpatico > Shifts" }
	},
	{ 
		path: '/app/module/simpatico/shift/job/:jobId/client/:clientId',
		name: "mobile-app-simpatico-shift", 
		component: () => import("./mobile-app/module/simpatico/shift.vue"),
		meta: { layout: "no", layoutType: "default", layoutContainer: "card", pageTitle: "Simpatico > Shift" }
		//meta: { layout: "mobileapp", layoutType: "default", layoutContainer: "card", pageTitle: "Simpatico > Shift" }
	},




















	//---- "Mobile App" for "Tessta"
	{ 
		path: '/app/module/tessta',
		name: "mobile-app-tessta-landing", 
		component: () => import("./mobile-app/module/tessta/index.vue"),
		meta: { layout: "no", layoutType: "default", layoutContainer: "card", pageTitle: "Appp > Tessta > Landing" }
	},

	{ 
		path: '/app/module/tessta/site/:siteId',
		name: "mobile-app-tessta-site", 
		component: () => import("./mobile-app/module/tessta/index.vue"),
		meta: { layout: "no", layoutType: "default", layoutContainer: "card", pageTitle: "Appp > Tessta > SIte" }
	},

	{ 
		path: '/app/module/tessta/a1/:siteId/:assessmentId',
		name: "mobile-app-tessta-a1", 
		component: () => import("./mobile-app/module/tessta/index.vue"),
		meta: { layout: "no", layoutType: "default", layoutContainer: "card", pageTitle: "Appp > Tessta > Assessment 1" }
	},

	{ 
		path: '/app/module/tessta/a2b/:siteId/:assessmentId',
		name: "mobile-app-tessta-a2b", 
		component: () => import("./mobile-app/module/tessta/index.vue"),
		meta: { layout: "no", layoutType: "default", layoutContainer: "card", pageTitle: "Appp > Tessta > Assessment 2: Building" }
	},

	{ 
		path: '/app/module/tessta/a2bFloor/:siteId/:assessmentId/:floorId',
		name: "mobile-app-tessta-a2bFloor", 
		component: () => import("./mobile-app/module/tessta/index.vue"),
		meta: { layout: "no", layoutType: "default", layoutContainer: "card", pageTitle: "Appp > Tessta > Assessment 2: Building > Floor" }
	},

	{ 
		path: '/app/module/tessta/a2bRoom/:siteId/:assessmentId/:floorId/:roomId',
		name: "mobile-app-tessta-a2bRoom", 
		component: () => import("./mobile-app/module/tessta/index.vue"),
		meta: { layout: "no", layoutType: "default", layoutContainer: "card", pageTitle: "Appp > Tessta > Assessment 2: Building > Room" }
	},

	{ 
		path: '/app/module/tessta/a2o/:siteId/:assessmentId',
		name: "mobile-app-tessta-a2o", 
		component: () => import("./mobile-app/module/tessta/index.vue"),
		meta: { layout: "no", layoutType: "default", layoutContainer: "card", pageTitle: "Appp > Tessta > Assessment 2: Open Space" }
	},

	{ 
		path: '/app/module/tessta/defect/:siteId/:assessmentId/:floorId:/:roomId/:assessmentItemId',
		name: "mobile-app-tessta-defect", 
		component: () => import("./mobile-app/module/tessta/index.vue"),
		meta: { layout: "no", layoutType: "default", layoutContainer: "card", pageTitle: "Appp > Tessta > Defect" }
	},

	{ 
		path: '/app/module/tessta/query/:siteId/:assessmentId/:floorId:/:roomId/:assessmentItemId',
		name: "mobile-app-tessta-query", 
		component: () => import("./mobile-app/module/tessta/index.vue"),
		meta: { layout: "no", layoutType: "default", layoutContainer: "card", pageTitle: "Appp > Tessta > Query" }
	},




]


export default routes;