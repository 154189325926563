<template>
	<textarea 
		:id="id" :name="getName" 
		:class="['c-input-textarea', 'c-input-native-base-restyle', cssClass, isValid == false ? 'is-invalid' : '', 'c-input-textarea-native']" 
		:style="getCssStyle"
		@input="onInput"
		:value="modelValue"
		:disabled="disabled"
	></textarea>

	<span class="validation-text" v-if="isValid == false && validationMessage != ''">
		<small style="color: red" v-html="validationMessage"></small>
	</span>
</template>





<script>
import allFormElementPropsMixin from "./all-form-element-props-mixin";

export default {

	emits: ["change", "input", "update:modelValue"],

	mixins: [allFormElementPropsMixin],

	props: {

		//TODO: Obsolete/deprecated. Don't remove until removing from implementations.
		initialValue: {
			type: [String, Number,null],
		},



		//TODO: Not implemented
		rows: {
			type: Number,
			required: false,
			default: 2,
		},



        modelValue: {
            type: [String, Number,null],
            default: '',
            required: true
        },

	},






	data()
	{
		return {
			value: ""
		};
	},





	mounted()
	{

	},





	watch: {

	},





	computed: {
		getName() {
			return this.name ? this.name : this.id;
		}
	},





	methods: {
		onInput($event)
		{
			const value = $event.target.value;

			this.$emit('change', value);

			//TODO: Make this obsolete and remove.
			this.$emit('input', value);

			this.$emit('update:modelValue', value);
		},
	}
}
</script>





<style scoped lang="scss">
	@import "/assets/sass/_global.scss";
	
	.c-input-textarea-native
	{
		width: 100%;

		// This is to approximately match the styling of the select list (and I presume text box) in Element UI
		padding: 10px;
		border-radius: 4px;

		border: 1px solid #DCDFE6;
	}
	.c-input-textarea-native:focus
	{
		border-color: #409EFF;
	}
</style>