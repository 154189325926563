<template>
	<input type="range" 
		:id="id" :name="getName" 
		:class="['c-input-range', cssClass, isValid == false ? 'is-invalid' : '']" 
		:style="getCssStyle"
		:min="min" 
		:max="max" 
		:step="step" 
		:value="modelValue" 
		@input="onInput"
		>
	<span class="validation-text" v-if="isValid == false && validationMessage != ''">
		<small style="color: red" v-html="validationMessage"></small>
	</span>
</template>





<script>
import allFormElementPropsMixin from "./all-form-element-props-mixin";

export default {

	emits: ["change", "update:modelValue"],

	mixins: [allFormElementPropsMixin],

	props: {

		min: {
			type: Number,
			default: 0
		},
		


		max: {
			type: Number,
			default: 100
		},
		


		step: {
			type: Number,
			default: 1
		},



        modelValue: {
            type: [Number, String],
            default: 0,
            required: true
        },
	},





	data()
	{
		return {
			
		};
	},





	mounted()
	{
		this.init();
	},





	computed: {
		getName() {
			return this.name ? this.name : this.id;
		}
	},





	methods: {
		init()
		{
			this.widthOnContainer = true;
		},



		onInput($event)
		{
			this.$emit('change', $event.target.value);
			
			this.$emit('update:modelValue', $event.target.value);
		},
	}
}
</script>





<style scoped lang="scss">
	@import "/assets/sass/_global.scss";
</style>