<template>
	<span>
		<input type="checkbox" 
			:id="id" 
			:name="getName" 
			:disabled="disabled"
			:class="['c-input-checkbox', 'c-input-checkbox-native-style', cssClass, isValid == false ? 'is-invalid' : '']" 
			:checked="modelValue"
			@input="onInput"
		>

		<span class="validation-text" v-if="isValid == false && validationMessage != ''">
			<small style="color: red" v-html="validationMessage"></small>
		</span>
	</span>
</template>





<script>
import allFormElementPropsMixin from "./all-form-element-props-mixin";

export default {

	mixins: [allFormElementPropsMixin],





	emits: ["change", "update:modelValue"],





	props: {
        modelValue: {
            type: Boolean,
            default: false,
            required: true
        },
	},





	data()
	{
		return {
		};
	},





	mounted()
	{
		this.value = this.modelValue;
	},





	watch: {
		//TODO: Obsolete/deprecated. Don't remove until removing from implementations.
		initialValue: function(to, from)
		{
			this.value = this.initialValue;
		},


		//TODO: Obsolete/deprecated. Don't remove until removing from implementations.
		updateValue: function(to, from)
		{
			this.value = this.updateValue;
		}
	},





	computed: {
		getName() {
			return this.name ? this.name : this.id;
		}
	},





	methods: {

		onInput(event)
		{
			const value = event.target.checked;

			this.$emit('change', value);

			this.$emit('update:modelValue', value);
		},


	}
}
</script>





<style scoped lang="scss">
	@import "/assets/sass/_global.scss";


	//Source: https://stackoverflow.com/questions/8635317/how-do-change-the-color-of-the-text-of-an-option-within-a-select
	.c-input-checkbox-native-style
	{
		/* Double-sized Checkboxes */
		-ms-transform: scale(1.2); /* IE */
		-moz-transform: scale(1.2); /* FF */
		-webkit-transform: scale(1.2); /* Safari and Chrome */
		-o-transform: scale(1.2); /* Opera */
		transform: scale(1.2);
	}
</style>
