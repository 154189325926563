<template>
	<label :class="['c-input-label', cssClass]" :style="cssStyle" :for="forName">
		<slot></slot><span class="required-field-indicator" style="padding-left: 4px;" v-if="isRequired">*</span>
	</label>
</template>





<script>

export default {

	props: {
		
		//NOTE: Vue will inherit and attach the "for" attribute automatically. No need to define it as a custom property.
		//		HOWEVER, we want to have a required check to ensure it is always included, hence the custom property.
		/**
		 * The `name` of the field this label is linked to.
		 */
		forName: {
			type: String,
			required: true
		},


		
		/**
		 * Additional CSS class(s) to apply.  
		 * Does NOT overwrite default classes.
		 */
		cssClass: {
			type: String
		},



		/**
		 * Additional CSS styles to apply.  
		 */
		cssStyle: {
			type: String
		},



		/**
		 * Is the field this label is attached to a required field?
		 */
		isRequired: {
			type: Boolean,
			default: false
		},

		

		/**
		 * Values include: "standard"; "primary"; "secondary"; "teriary" "link".
		 */
		type: {
			type: String,
			default: "standard"
		}
		
	},





	mounted() 
	{
		if (this.forName == "")	console.error("c-input-label: 'forName' is missing a value.");
	},




	data()
	{
		return {
			
		};
	},





	computed: {
		
	},





	methods: {

	}
}
</script>





<style scoped lang="scss">
	@import "/assets/sass/_global.scss";

	.c-input-label
	{
		// dsfsdf
		
	}



</style>