const routes = [

	
	//---- Test
	{ 
		path: '/test', 
		name: "test-index", 
		component: () => import("./test/index.vue"),
		meta: { layout: "no", layoutType: "", isPublic: true, pageTitle: "Test E" } 
		//meta: { layout: "public", layoutType: "default", isPublic: true, pageTitle: "Test B" } 
		//meta: { layout: "primary", layoutType: "default", layoutContainer: "none", isPublic: true, pageTitle: "Test C", kbFile: "home", kbTitle: "Test C" } 
	},
	{ 
		path: '/test/simple-form', 
		name: "test-simple-form", 
		component: () => import("./test/simple-form.vue"),
		meta: { layout: "primary", layoutType: "", isPublic: true, pageTitle: "Test Simple Form Renderer" } 
	},
	{ 
		path: '/test/simple-table', 
		name: "test-simple-table", 
		component: () => import("./test/simple-table.vue"),
		meta: { layout: "primary", layoutType: "", isPublic: true, pageTitle: "Test Simple Table Renderer" } 
	},




	{ 
		path: '/test/generate-error', 
		name: "test-generate-error", 
		component: () => import("./test/generate-error.vue"),
		meta: { layout: "no", layoutType: "", isPublic: true, pageTitle: "Test > Generate Error" } 
	},


	{ 
		path: '/test/chatgpt/table2', 
		name: "test-chatgpt-table2", 
		component: () => import("./test/chatgpt/table2-host.vue"),
		meta: { layout: "primary", layoutType: "", isPublic: true, pageTitle: "Test > ChatGPT > Table 2" } 
	},

]


export default routes;