<template>
	<button
		:id="id"
		:class="['c-action-button', 'text-dark', 'btn', 'btn-outline-primary', 'btn-sm', cssClass]" 
		@click="onClick" 
		:disabled="disabled ? true : false"
		:title="title"
	>
		<span :class="['icon', hasText() ? 'icon-with-text' : '']" v-if="hasIcon()"><slot name="icon"></slot></span>
		<span class="text" v-if="hasText()"><slot name="text"></slot></span>
	</button>
</template>

<script>
import buttonPropsMixin from "./button-props-mixin";
import allFormElementPropsMixin from "../forms/all-form-element-props-mixin";

export default {
	name: 'ActionButton',

	emits: ["click"],

	mixins: [allFormElementPropsMixin, buttonPropsMixin],

	data() {
		return {
			
		};
	},

	mounted() {
		
	},

	methods: {
		onClick() {
			this.$emit('click');
		},


		hasIcon()
		{
			//Ref: https://stackoverflow.com/questions/50133153/get-rendered-html-contents-of-vue-slot-as-variable
			//Ref: https://eslint.vuejs.org/rules/require-slots-as-functions.html
			// Part 1) Ensure the slot is actually included in the calling component.
			// Part 2) Check there is HTML content within the slot in the calling component.
			const hasValue = !!this.$slots['icon'] && this.$slots.icon().length > 0;
			return hasValue;
		},

		hasText()
		{
			//Ref: https://stackoverflow.com/questions/50133153/get-rendered-html-contents-of-vue-slot-as-variable
			//Ref: https://eslint.vuejs.org/rules/require-slots-as-functions.html
			// Part 1) Ensure the slot is actually included in the calling component.
			// Part 2) Check there is HTML content within the slot in the calling component.
			const hasValue = !!this.$slots['text'] && this.$slots.text().length > 0;
			return hasValue;
		}
	},
};
</script>







<style scoped lang="scss">
@import "./shared-styles.scss";
//-- Start: Copied these in from DOM inspection. I think they came from Bootstrap

.btn {
    display: inline-block;
    font-weight: 400;
    color: #222f38;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 1rem 2rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 1.85rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


.btn-outline-primary {
    color: #ffb600;
    border-color: #ffb600;
}


.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 1rem;
}




.text-dark {
    color: #222f38 !important;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}


.btn-outline-primary:hover {
    color: #212529;
    background-color: #ffb600;
    border-color: #ffb600;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
    color: #212529;
    background-color: #ffb600;
    border-color: #ffb600;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0 rgba(255, 182, 0, 0.5);
}
//-- End: Copied these in from DOM inspection. I think they came from Bootstrap




.c-action-button
{
	background-color: #ffffff;
	border-radius: 4px;
}
.icon-with-text {
	margin-right: 10px		
}
</style>