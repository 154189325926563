<template>
	<input type="number" 
		:id="id" :name="getName" 
		:class="['c-input-number', 'c-input-native-base-restyle', cssClass, isValid == false ? 'is-invalid' : '']" 
		:style="getCssStyle"
		:min="min" 
		:max="max" 
		:step="step" 
		:value="modelValue"
		:disabled="disabled"
		@input="onInput"
		@keypress="onKeyPress"
	>
	<span class="validation-text" v-if="isValid == false && validationMessage != ''">
		<small style="color: red" v-html="validationMessage"></small>
	</span>
</template>





<script>
import allFormElementPropsMixin from "./all-form-element-props-mixin";

export default {


	emits: ["change", "blur", "update:modelValue"],





	mixins: [allFormElementPropsMixin],





	props: {

		min: {
			type: Number,
			default: 0
		},
		


		max: {
			type: Number
		},
		


		step: {
			type: Number,
			default: 1
		},
		


		/**
		 * Set to true to only allow integer digits (0 to 9).
		 * Disallow decimal (.) and dash (-) or other characters HTML "number" input allows.
		 */
		integerDigitsOnly: {
			type: Boolean,
			default: false
		},
		


		//TODO: Obsolete/deprecated. Don't remove until removing from implementations.
		initialValue: {
			type: [Number, String]	// "String", because the value may be coming from a stored string
		},



		controls: {
			type: Boolean,
			default: true,
		},



		// number of decimal places
		precision: {
			type: Number,
			default: null,
		},



		rightAlign: {
			type: Boolean,
			default: false,
		},



        modelValue: {
            type: [Number, String, null],
			default: "",
            required: true
        },
	},





	data()
	{
		return {
			value: 0
		};
	},





	mounted()
	{
	},





	watch: {
	},





	computed: {
		getName() {
			return this.name ? this.name : this.id;
		},



		// REF: https://vuejs.org/guide/essentials/template-syntax.html#attribute-bindings
		// conditionally applies the precision attribute to a component.
		maybePrecision() {
			return this.precision == null ? {} : {precision: this.precision};
		},
	},





	methods: {
		onInput(event)
		{
			//console.log("onInput(): ", event.target.value);

			// this.$emit('input', $event.target.value);
			this.$emit('change', event.target.value);

			this.$emit('update:modelValue', event.target.value);
		},




		//TODO: What was I trying to achieve here????
		onKeyPress(event)
		{
			//console.log("onKeyPress(): event.charCode", event.charCode, event);
			//console.log("onKeyPress(): this.integerDigitsOnly", this.integerDigitsOnly);

			if (this.integerDigitsOnly == false) return;	// Don't need to check

			// Ref: https://stackoverflow.com/questions/39291997/how-to-block-e-in-input-type-number
			if (event.which != 8 	// Backsapce
				&& event.which != 0 	// null
				&& event.which < 48 || event.which > 57	// 0 to 9
			)
			{
				event.preventDefault();
			}

			//return event.charCode >= 48 && event.charCode <= 57;
		}

	}
}
</script>





<style scoped lang="scss">
	@import "/assets/sass/_global.scss";

	// target el-input__inner 
	.align-right::v-deep input.el-input__inner {
		text-align: right;
	}
	

	.c-input-number
	{
		// min-width: 200px;
		width: 100%;	// Other wise it goes wide for some reason.
	}
</style>